<script setup lang="ts">
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  titlesize: {
    type: String,
    default: 'lg',
  },
  subtitle: {
    type: String,
    required: true,
  },
})
</script>

<template>
  <div>
    <div class="text-sm uppercase font-medium text-zinc-400">
      {{ props.subtitle }}
    </div>
    <h2
      class="text-3xl uppercase font-light text-gray-700" :class="{
        'text-3xl': titlesize === 'lg',
        'text-2xl': titlesize === 'md',
        'text-xl': titlesize === 'sm',
      }"
    >
      {{ props.title }}
    </h2>
  </div>
</template>
